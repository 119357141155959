import { FC, Component } from 'react';
import { DefaultLayout } from 'layouts';

export const withPublicPage = (WrappedComponent: FC) => {
    class HOC extends Component {
        render = () => (
            <DefaultLayout>
                <WrappedComponent {...this.props}/>
            </DefaultLayout>
        );
    };
    return HOC;
}