import { withPublicPage } from 'hoc';
import Box from '@mui/material/Box';
import { PageTitle, ProductCard } from 'components';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

export const Home = withPublicPage(() => {
    return(
        <Box padding={2}>
            <PageTitle>
                Welcome to IntoTheNerdVerse.com!
            </PageTitle>
            <Typography paragraph>
                We're in the process of getting our website constructed, so in the mean time <Link sx={{cursor:'pointer'}} href={process.env.REACT_APP_COUPON_URL}>check out our store</Link> and save 10% on your first order!
            </Typography>
            <Typography paragraph>
                Here are just a few on the products we are offering.  All of our clothing items are available in <Typography display="inline" fontWeight='bold'>multiple colors</Typography>!
            </Typography>
            <Box 
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                justifyContent='space-around'
            >
                <ProductCard 
                    title="Nerdherfer T-Shirt"
                    url="https://intothenerdverse.etsy.com/listing/1591841520/nerdherfer-t-shirt-multiple-colors"
                    image="https://i.etsystatic.com/21770382/r/il/5fe733/5530415699/il_fullxfull.5530415699_5zms.jpg"
                />
                <ProductCard
                    title="Dadbod CSS T-Shirt"
                    url="https://www.etsy.com/listing/1592559156/dadbod-css-t-shirt-mulitple-colors"
                    image="https://i.etsystatic.com/21770382/r/il/36d7c7/5485491478/il_1588xN.5485491478_nmsa.jpg"
                />
                <ProductCard 
                    title="CSS Hooded Sweatshirt"
                    url="https://www.etsy.com/listing/1605993549/css-unisex-heavy-blend-hooded-sweatshirt"
                    image="https://i.etsystatic.com/21770382/r/il/705b17/5482245458/il_1588xN.5482245458_auk9.jpg"
                />
                <ProductCard 
                    title="I Know HTML Mug"
                    url="https://www.etsy.com/listing/1591747080/i-know-html-accent-coffee-mug-11oz"
                    image="https://i.etsystatic.com/21770382/r/il/c74adb/5529928339/il_1588xN.5529928339_pjn1.jpg"
                />
            </Box>
        </Box>
    )
})

export default Home;