import { PropsWithChildren } from 'react';
import Typography, { TypographyProps } from '@mui/material/Typography';
import {Link} from 'react-router-dom';
import {ROUTES} from 'routes';
import WestIcon from '@mui/icons-material/West';
import useMediaQuery from '@mui/material/useMediaQuery';

interface HeaderProps extends TypographyProps {
    as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    compact?: boolean;
}

export const Header = ({ children, as = 'h1', ...rest }:PropsWithChildren & HeaderProps) => <Typography variant={as} component={as} { ...rest }>{children}</Typography>

export const PageTitle = ({ textAlign = 'left', compact = false, children }: TypographyProps & { compact?: boolean; }) => {
    const matches = useMediaQuery('(max-width:768px)');
    return <Typography textAlign={textAlign} component="h1" variant={matches ? "h5" : "h4"} mb={compact ? 0 : 3}>{children}</Typography>
}

export const ReturnHeader = ({ to, children, as, textAlign = 'left', ...rest }:PropsWithChildren & HeaderProps & { to?: string }) => {
    const matches = useMediaQuery('(max-width:768px)');
    return (
        <Typography 
            textAlign={textAlign} 
            component={'h1'} 
            variant={as || matches ? 'h6' : 'h4'} 
            { ...rest}
        >
            <Link to={to || ROUTES.home}>
                <WestIcon
                    sx={{
                        display: 'inline',
                        verticalAlign: 'middle',
                        marginRight: '10px',
                    }}
                />
            </Link>
            {children}
        </Typography>
    );
}