import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

export const ProductCard = ({
    title,
    url,
    image,
}:{
    title: string;
    url: string;
    image: string;
}) => {

    return (
        <Card sx={{ width: '20%', maxWidth: '285px' }}>
            <CardActionArea onClick={() => window.location.href = url}>
                <CardHeader title={title} sx={{textAlign: 'center'}}/>
                <CardMedia>
                    <img src={image} alt={title} width='100%'/>
                </CardMedia>
                <CardContent>

                </CardContent>
            </CardActionArea>
        </Card>
    )
}