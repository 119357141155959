import { BrowserRouter as Router } from 'react-router-dom';
import DefaultRoutes from './default';

export const ROUTES = {
    home: '/',
}

export const InternalRoutes = () => {
    return (
        <Router>
            <DefaultRoutes/>
        </Router>
    )
}

export default InternalRoutes;