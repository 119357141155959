import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { TikTokIcon } from 'components';

import Link from '@mui/material/Link';

export const DefaultLayout = ({
    children = null, 
}:{ children: any }) => {

  return (
      <PageContainer id="desktop-page-container">
        <LayoutContainer id="layout-container">
          <Box 
            padding={1}
            flex='0 1 auto'
            width='100%'
            sx={{
              backgroundColor: '#121212',
              color: '#fffff0',
            }}
          >
            {process.env.REACT_APP_NAME}
          </Box>
          <Box 
            flex='1 1 auto'
            display='flex'
            flexDirection='row'
            flexWrap='nowrap'
          >
            <Box flex='0 1 auto'>
              <Stack direction="column">
                <Box flex='0 1 auto'>
                  <img src="/img/into-the-nerdverse-logo-web.png" alt="IntoTheNerdVerse.com"/>
                </Box>
                <Box mt={3} padding={3}>
                  <Stack direction="row" spacing={2} justifyContent='center'>
                    <Link href="https://www.facebook.com/n2thenerdverse" sx={{ cursor: 'pointer' }}>
                      <FacebookIcon fontSize='large' htmlColor='#4267B2'/>
                    </Link>
                    <Link href="https://www.instagram.com/n2thenerdverse/" sx={{ cursor: 'pointer' }}>
                      <InstagramIcon fontSize='large' htmlColor='#fa7e1e'/>
                    </Link>
                    <Link href="https://tiktok.com/!n2thenerdverse" sx={{ cursor: 'pointer' }}>
                      <TikTokIcon fontSize='large'/>
                    </Link>
                  </Stack>
                </Box>
              </Stack>
            </Box>
            <Box flex='1 1 auto'>
              {children}
            </Box>
          </Box>
          <Box 
            flex='0 1 auto'
            width='100%'
            padding={2}
            textAlign='center'
            sx={{
              backgroundColor: '#121212',
              color: '#fffff0',
            }}
          >
            &copy;2023 IntoTheNerdVerse by 421, LLC
          </Box>
        </LayoutContainer>
      </PageContainer>
  );

};

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: stretch;
  min-height: 100vh;
  position: relative;
  margin: 0;
`;

const LayoutContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: stretch;
`;
