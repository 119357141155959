import {Route, Routes} from 'react-router-dom';
//import loadable from '@loadable/component';

import {ROUTES} from 'routes';

import {Home} from './Home';


export const routes = () => (
  <Routes>
    <Route index path={ROUTES.home} element={<Home />} />
  </Routes>
);

export default routes;

